import PropTypes from 'prop-types';
import React from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks';
import { useSpring, animated } from 'react-spring';
import { css } from '@emotion/core';

const header = css`
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
`;

const container = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1400px;
    margin: auto;
    padding: 1.5rem 2rem 0;

    @media (min-width: 52rem) {
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 0.5rem;
    }
`;

const title = css`
    font-family: 'Lato', sans-serif;
    font-size: 1.4rem;
    letter-spacing: 0.3rem;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;

    @media (min-width: 31rem) {
        font-size: 1.6rem;
    }
`;

const link = css`
    color: inherit;
    text-decoration: none;
`;

const navigation = css`
    display: flex;
`;

const navLink = isHome => css`
    font-family: 'Lato', sans-serif;
    color: inherit;
    text-decoration: none;
    padding: 0.5rem;
    margin: 0 0.3rem;
    font-size: 0.7rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-weight: 600;
    position: relative;

    @media (min-width: 31rem) {
        font-size: 0.8rem;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0.4rem;
        left: 0;
        width: 100%;
        height: 2px;
        background: ${isHome ? 'white' : '#2d2d2d'};
        transition: transform 0.2s;
        transform: scaleX(0);
    }

    &:hover::after {
        transform: scaleX(1);
    }
`;

const Header = ({ siteTitle, isHome, hasShadow }) => {
    const { transitionStatus } = useTransitionState();
    const { transform } = useSpring({
        transform: isHome && transitionStatus.startsWith('enter') ? 0 : 1,
    });
    const { shadow } = useSpring({
        shadow: hasShadow ? 1 : 0,
    });
    const navLinkCss = navLink(isHome);

    return (
        <animated.header
            css={header}
            style={{
                transform: shadow.interpolate(y => `translateY(-${y}rem)`),
                color: transform.interpolate(y => `rgb(${255 * (1 - y)}, ${255 * (1 - y)}, ${255 * (1 - y)})`),
                background: !isHome
                    ? shadow.interpolate(s => `rgba(255, 255, 255, ${s})`)
                    : transform.interpolate(y => `linear-gradient(rgba(0, 0, 0, ${0.7 * (1 - y)}), rgba(0, 0, 0, 0))`),
                boxShadow: shadow.interpolate(
                    x => `0 3px 6px rgba(0, 0, 0, ${0.16 * x}), 0 3px 6px rgba(0, 0, 0, ${0.23 * x})`
                ),
            }}
        >
            <div css={container}>
                <h1 css={title}>
                    <TransitionLink
                        css={link}
                        to="/"
                        exit={{ length: 1, zIndex: 0, state: { toHome: true } }}
                        entry={{ length: 1, zIndex: 100 }}
                    >
                        {siteTitle}
                    </TransitionLink>
                </h1>
                <nav css={navigation}>
                    <TransitionLink
                        css={navLinkCss}
                        to="/"
                        exit={{ length: 1, zIndex: 0, state: { toHome: true } }}
                        entry={{ length: 1, zIndex: 100 }}
                    >
                        Home
                    </TransitionLink>
                    <TransitionLink
                        css={navLinkCss}
                        to="/gallery"
                        exit={{ length: 1, zIndex: 100 }}
                        entry={{ length: 1, zIndex: 0, state: { fromHome: isHome } }}
                    >
                        Gallery
                    </TransitionLink>
                    <TransitionLink
                        css={navLinkCss}
                        to="/about"
                        exit={{ length: 1, zIndex: 100 }}
                        entry={{ length: 1, zIndex: 0, state: { fromHome: isHome } }}
                    >
                        About
                    </TransitionLink>
                    <TransitionLink
                        css={navLinkCss}
                        to="/contact"
                        exit={{ length: 1, zIndex: 100 }}
                        entry={{ length: 1, zIndex: 0, state: { fromHome: isHome } }}
                    >
                        Contact
                    </TransitionLink>
                </nav>
            </div>
        </animated.header>
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string,
    isHome: PropTypes.bool,
    hasShadow: PropTypes.bool,
};

Header.defaultProps = {
    siteTitle: ``,
    isHome: false,
    hasShadow: false,
};

export default Header;
