import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { css } from '@emotion/core';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const wrapper = css`
    min-height: 100%;
    top: 50%;
    transform: translateY(-50%);
`;

const HeroImage = ({ name }) => {
    const data = useStaticQuery(graphql`
        query {
            hero: file(relativePath: { eq: "static/hero.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            self: file(relativePath: { eq: "static/natalie_schott.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            pose: file(relativePath: { eq: "static/natalie_schott_pose.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    const conditionalProps =
        name === 'hero'
            ? {
                  css: wrapper,
                  objectFit: 'cover',
              }
            : {};

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Img fluid={data[name].childImageSharp.fluid} {...conditionalProps} />;
};

export default HeroImage;
