import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { useSpring, animated } from 'react-spring';
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks';

const footer = css`
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 50%;
    width: 100%;
    max-width: 1400px;
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 0.8rem;
    padding: 1rem 1.8rem;
`;

const Footer = ({ isHome }) => {
    const { transitionStatus } = useTransitionState();
    const { transform } = useSpring({
        transform: isHome && transitionStatus.startsWith('exit') ? 1 : 0,
    });

    return (
        <animated.footer
            css={footer}
            style={{
                position: isHome ? 'absolute' : 'relative',
                color: isHome ? 'white' : '#2d2d2d',
                transform: transform.interpolate(t => `translate(-50%, -${10 * t}vh)`),
                opacity: transform.interpolate(t => 1 - t),
                textAlign: isHome ? 'right' : 'center',
            }}
        >
            © {new Date().getFullYear()} Natalie Schott
        </animated.footer>
    );
};

Footer.propTypes = {
    isHome: PropTypes.bool,
};

Footer.defaultProps = {
    isHome: false,
};

export default Footer;
