/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Global, css } from '@emotion/core';
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks';
import { useSpring, animated } from 'react-spring';

import Header from './Header';
import Footer from './Footer';
import reset from './reset.css';

const resetOveride = css`
    html {
        overflow: auto;
    }

    .tl-wrapper-status--exiting {
        pointer-events: none;
    }
`;

const flexWrapper = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
`;

const navWrapper = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
`;

const contentWrapper = css`
    flex: 1 1 100%;
    position: relative;
`;

const Layout = ({ children, isHome }) => {
    const { transitionStatus, current } = useTransitionState();
    const { opacity } = useSpring({
        opacity: !isHome && transitionStatus.startsWith('exit') && !current.state.toHome ? 0 : 1,
        config: { tension: 400 },
        immediate: current.state.fromHome,
    });
    const contentRef = React.useRef();
    const [hasShadow, setShadow] = React.useState(false);
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    React.useEffect(() => {
        const handleScroll = () => {
            if (contentRef.current.scrollTop === 0 && hasShadow) {
                setShadow(false);
            } else if (contentRef.current.scrollTop > 0 && !hasShadow) {
                setShadow(true);
            }
        };

        contentRef.current.addEventListener('scroll', handleScroll);

        return () => contentRef.current.removeEventListener('scroll', handleScroll);
    }, [hasShadow]);

    return (
        <>
            <Global styles={reset} />
            <Global styles={resetOveride} />
            <animated.div
                css={flexWrapper}
                ref={contentRef}
                style={{
                    background: isHome ? 'transparent' : 'white',
                    opacity,
                }}
            >
                <div css={navWrapper}>
                    <Header hasShadow={hasShadow} siteTitle={data.site.siteMetadata.title} isHome={isHome} />
                </div>
                <div css={contentWrapper}>
                    <main>{children}</main>
                    <Footer isHome={isHome} />
                </div>
            </animated.div>
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    isHome: PropTypes.bool,
};

Layout.defaultProps = {
    isHome: false,
};

export default Layout;
