import { css } from '@emotion/core';

export const container = css`
    max-width: 1200px;
    padding: 1.5rem;
    margin: auto;
    margin-top: 6rem;
    text-align: center;
`;

export const title = css`
    display: inline-block;
    padding: 0 1.5rem;
    font-family: 'Prata', sans-serif;
    font-size: 2.4rem;
    letter-spacing: 0.3rem;
    text-transform: uppercase;
    font-weight: 200;
    text-align: center;
    position: relative;
    margin-bottom: 4rem;

    @media (min-width: 37rem) {
        font-size: 3rem;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0.1rem;
        left: 50%;
        width: 100%;
        height: 0.35em;
        background: #f48fb1;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%) skew(-40deg, 0deg);
        z-index: -1;
    }
`;
